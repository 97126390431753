import { Stack, Text, Image, IStackTokens, ImageFit } from '@fluentui/react';

const containerStackToken: IStackTokens = {
    childrenGap: 10,
    padding: 5
}


const ContactUs = (): JSX.Element => (
    <div className="ms-Grid">
        <div className="ms-GridRow">
            <div className="ms-Grid-col ms-sm12 ms-lg12 ms-xxl9">
                <Stack tokens={containerStackToken}>
                    <Text variant="xLarge" block>
                        Contact Us
                    </Text>
                    <Text>
                        If you would like more information or have suggestions for future mission trips,
                        please contact Al Mullen.

                    </Text>
                    <Text variant="large" block>
                        Web Site
                    </Text>
                    <Text>
                        To make suggestions or corrections to the web site, please contact Wade Poust.
                    </Text>
                </Stack>

            </div>
            <div className="ms-Grid-col ms-sm12 ms-md8 ms-xxl3">
                <Image src="/images/Al.jpg" alt="Al Mullen" imageFit={ImageFit.none} />

            </div>
        </div>
    </div>
)

export default ContactUs;