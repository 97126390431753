import * as React from 'react';
import { Image, ImageFit, IImageStyles } from '@fluentui/react/lib/Image';

const headerStyle : IImageStyles = {
    image: {

    },
    root : {
        maxWidth: 860
    }
}


const SiteHeader = () : JSX.Element => (
    <header>
        <Image src="/images/Logo.jpg" shouldStartVisible={true} shouldFadeIn={false} imageFit={ImageFit.contain} styles={headerStyle}/>
    </header>
);

export default SiteHeader;