import React from 'react';
import {  INavLinkGroup, Nav } from '@fluentui/react';

const navLinks : INavLinkGroup[] = [
    {
        links: [
            {
                name: 'Home',
                url: './'
            },
            {
                name: 'Upcoming trips',
                url: './upcomingtrips'
            },
            {
                name: 'Trip archives',
                url: './archives'
            },
            {
                name: 'About us',
                url: './about'
            },
            {
                name: 'Contact us',
                url: './contact'
            },
            {
                name: 'FAQ',
                url: './faq'
            },
        ]
    }
];

  
const LeftMenu = (): JSX.Element => (
        <Nav groups={navLinks}/>
);

export default LeftMenu;