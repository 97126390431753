import React from 'react';
import { Stack, IStackTokens } from '@fluentui/react';
import './App.css';
import LeftMenu from './LeftMenu';
import SiteHeader from './SiteHeader'
import SiteFooter from './SiteFooter';
import Content from './Content';

const containerStackToken : IStackTokens = {
  childrenGap:5,
  padding:5
}


export const App = (): JSX.Element => {
  return (
    <div className="ms-Grid" dir="ltr">
      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-hiddenMdDown ms-lg1"></div>
        <div className="ms-Grid-col ms-sm12 ms-lg10 mainContent">
          <SiteHeader />
          <Stack horizontal horizontalAlign="stretch" verticalAlign="stretch" verticalFill tokens={containerStackToken} >
            <LeftMenu />
            <Stack  grow={true} className="ms-depth-16">
              <Content />
            </Stack>
          </Stack>
          <SiteFooter/>
        </div>
        <div className="ms-Grid-col ms-hiddenMdDown ms-lg1 gutter"></div>
      </div>
    </div>
  );
};
