import { Stack, Text, Image, IStackTokens, ImageFit } from '@fluentui/react';

const containerStackToken: IStackTokens = {
    childrenGap: 10,
    padding: 5
}

const AboutUs = (): JSX.Element => (
    <div className="ms-Grid">
        <div className="ms-GridRow">
            <div className="ms-Grid-col ms-sm12 ms-lg12 ms-xxl9">
                <Stack tokens={containerStackToken}>
                    <Text variant="xLarge" block>
                        About Us
                    </Text>
                    <Text>
                        Lutheran Builders of Western North Carolina is a volunteer organization. Its
                        goal is to provide building assistance to those who have been adversely affected by
                        disasters.&nbsp; The group schedules several mission trips throughout the year to
                        serve those in need.&nbsp; Most volunteers are members of various Lutheran
                        churches in western North Carolina.
                    </Text>
                </Stack>

            </div>
            <div className="ms-Grid-col ms-sm12 ms-md8 ms-xxl3">
                <Image src="/images/Paint.jpg" alt="Painting in New Orleans." imageFit={ImageFit.none}/>

            </div>
        </div>
    </div>
)

export default AboutUs;