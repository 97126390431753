import * as React from 'react';
import { Stack, Text, Link, IStackTokens, IStackStyles, DefaultPalette, ITextStyles, ILinkStyles, FocusZone, FocusZoneDirection } from '@fluentui/react';

const containerStackToken: IStackTokens = {
    childrenGap: 10,
    padding: 5,
}

const footerStackStyles: IStackStyles = {
    root: {
        background: DefaultPalette.themePrimary,
        color: DefaultPalette.white,
        marginTop:16
    }
}

const textStyles: ITextStyles = {
    root: {
        color: DefaultPalette.white,
    }
}

const linkStyles: ILinkStyles = {
    root: {
        color: DefaultPalette.white,
        selectors: {
            ':focus': {
                color: DefaultPalette.white,
            }
        }
    }
}

const SiteFooter = (): JSX.Element => (
    <footer>
        <Stack horizontalAlign="center" tokens={containerStackToken} styles={footerStackStyles}>
            <FocusZone direction={FocusZoneDirection.horizontal}>
            <Text>
                <Link href="./" styles={linkStyles}>Home</Link> |&nbsp;
                <Link href="./upcomingtrips" styles={linkStyles}>Upcoming trips</Link> | &nbsp;
                <Link href="./archives" styles={linkStyles}>Trip archives</Link> |&nbsp;
                <Link href="./about" styles={linkStyles}>About us</Link> |&nbsp;
                <Link href="./contact" styles={linkStyles}>Contact us</Link> | &nbsp;
                <Link href="./faq" styles={linkStyles}>FAQ</Link>
            </Text>
            </FocusZone>
            <Text styles={textStyles}>Copyright 2021. All Rights Reserved.</Text>
        </Stack>
    </footer>
);

export default SiteFooter;