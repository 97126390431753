import * as React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import Home from './Home';
import AboutUs from './AboutUs';
import UpcomingTrips from './UpcomingTrips';
import Archives from './Archives';
import ContactUs from './ContactUs';
import Faq from './Faq';

const Content = (): JSX.Element => (
    <BrowserRouter>
        <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/about" component={AboutUs} />
            <Route exact path="/upcomingtrips" component={UpcomingTrips} />
            <Route exact path="/archives" component={Archives} />
            <Route exact path="/contact" component={ContactUs} />
            <Route exact path="/faq" component={Faq} />
            <Redirect to="/" />
        </Switch>
    </BrowserRouter>
);

export default Content;