import { Stack, Text, Image, IStackTokens, Link, ImageFit } from '@fluentui/react';

const containerStackToken: IStackTokens = {
    childrenGap: 10,
    padding: 5,
}


const Home = (): JSX.Element => (
    <Stack tokens={containerStackToken}>
        <Text variant="xLarge" block>
            Welcome
        </Text>
        <div className="ms-Grid">
            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-lg12 ms-xxl8">
                    <Stack tokens={containerStackToken}>
                        <Text>
                            Welcome to the Lutheran Builders of Western North Carolina web site.&nbsp; This
                            site is designed to serve as a central place for communicating information about
                            upcoming relief trips.&nbsp; The organization is composed of volunteers
                            from Lutheran churches in Catawba County and surrounding areas of western North Carolina.&nbsp; The
                            group organizes relief trips to help victims in areas that have been
                            adversely affected by natural disasters, such as Hurricane Katrina damage
                            to the Gulf Coast.  Besides relief trips, the group also works with local organizations
                            to assist in building projects.
                        </Text>
                        <Text>
                            Additionally, the site contains pictures and information about previous relief
                            missions.&nbsp; Stories about the victims and response efforts provided by
                            our organization are important.&nbsp; Frequently the news media is quick to
                            cover a disaster but fails to follow up on the subsequent, long-term impact.&nbsp;
                            This is where our faith community steps in.
                        </Text>
                        <Text>
                            We encourage you to browse our web site and consider getting involved in an
                            upcoming relief trip.&nbsp; Responding to the call for help will certainly change the lives
                            of disaster victims and may change your life, too.
                        </Text>
                    </Stack>

                </div>
                <div className="ms-Grid-col ms-hiddenMdDown ms-lg12 ms-xl6 ms-xxl4">
                    <Image src="/images/Lutheranch-Painters.jpg" alt="Lutherranch Painters (2011)" imageFit={ImageFit.contain} />
                </div>
            </div>
        </div>
        <Text variant="xLarge" block>
            Upcoming Trips
        </Text>
        <Text>
            Interesting in getting involved?&nbsp; Check out the <Link href="/upcomingtrips">upcoming trips</Link> we
            are	planning.
        </Text>
        <Image src="/images/Painting.jpg" height="379px" width="300px" alt="A fresh coat of paint completes the job" />
        <Text variant="xLarge" block>
            Trip Archives
        </Text>
        <Text>
            View some photos of <Link href="/archives">previous trips</Link>.
        </Text>
        <Image src="/images/BiloxiGroup.jpg" height="300px" width="400px" alt="Biloxi Rebuilding group (2006)" />
    </Stack>
)

export default Home;