import { Stack, Text, Image, IStackTokens, ImageFit } from '@fluentui/react';

const containerStackToken: IStackTokens = {
    childrenGap: 10,
    padding: 5
}

const UpcomingTrips = (): JSX.Element => (
    <div className="ms-Grid">
        <div className="ms-GridRow">
            <div className="ms-Grid-col ms-sm12 ms-lg12 ms-xxl6">
                <Stack tokens={containerStackToken}>
                    <Text variant="xLarge" block>
                        Upcoming Trips
                    </Text>
                    <Text>
                        Currently, we are actively looking for potential projects to take on.  If
                        you have any ideas, please contact Al Mullen to see how we could fit in.
                    </Text>
                    <Text variant="xLarge" block>
                        Trip Reports
                    </Text>

                    <Text variant="large">Lutherock, (Newland, NC) deck construction - May 20, 2021</Text>
                    <Text>
                        After being sidelined for a year due to Covid-19, we were finally able to get a group
                        together to assist with building a deck on the Lutherock Founder's lodge.  We had a team
                        of 7 that enjoyed a day out in the fresh mountain air.  We installed deck floor joists.
                    </Text>


                    <Text variant="large">Fayetteville NC rebuilding - March 17-21, 2019</Text>
                    <Text>
                        We had 16 people make the trip to Fayetteville.  Despite missing some time
                        due to rain, we accomplished the following items:
                    </Text>
                    <ul>
                        <li>Installed laminate flooring in three rooms</li>
                        <li>Hung doors and door jambs</li>
                        <li>Installed shingle on most of one house</li>
                        <li>Corrected roof truss issues and installed sheeting on a roof</li>
                        <li>Painted a basement</li>
                        <li>Repairs a wooden gate frame at a house</li>
                        <li>Unboxed, assembled, and labelled new tools</li>
                        <li>Ate heartily</li>
                        <li>Sang and worshipped each night</li>
                        <li>Enjoyed great fellowship</li>
                    </ul>


                </Stack>

            </div>
            <div className="ms-Grid-col ms-sm12 ms-md10 ms-xxl6">
                <Stack tokens={containerStackToken}>
                <Image src="/images/Lutherock-deck.jpg" alt="Lutherock deck team" imageFit={ImageFit.contain} />
                <Image src="/images/TripPlanning.jpg" alt="Trip planning into New Orleans." imageFit={ImageFit.none} />
                </Stack>
            </div>
        </div>
    </div>
)

export default UpcomingTrips;