import { Stack, Text, IStackTokens, FocusZone, FocusZoneDirection, List, TextField, getFocusStyle, getRTL, getTheme, Icon, ITheme, mergeStyleSets, Modal, IconButton, FontWeights, IButtonStyles, IIconProps } from '@fluentui/react';
import React, { useEffect } from 'react';
import { useBoolean } from '@fluentui/react-hooks';

const containerStackToken: IStackTokens = {
    childrenGap: 10,
    padding: 5
}


interface ITripDetails {
    source: string;
    name: string;
    location: string;
    date: string;
}


const theme: ITheme = getTheme();
const { palette, semanticColors, fonts } = theme;

const classNames = mergeStyleSets({
    itemCell: [
        getFocusStyle(theme, { inset: -1 }),
        {
            minHeight: 54,
            padding: 10,
            boxSizing: 'border-box',
            borderBottom: `1px solid ${semanticColors.bodyDivider}`,
            display: 'flex',
            selectors: {
                '&:hover': { background: palette.neutralLight },
            },
        },
    ],
    itemImage: {
        flexShrink: 0,
    },
    itemContent: {
        marginLeft: 10,
        overflow: 'hidden',
        flexGrow: 1,
    },
    itemName: [
        fonts.xLarge,
        {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    ],
    itemIndex: {
        fontSize: fonts.small.fontSize,
        color: palette.neutralTertiary,
        marginBottom: 10,
    },
    chevron: {
        alignSelf: 'center',
        marginLeft: 10,
        color: palette.neutralTertiary,
        fontSize: fonts.large.fontSize,
        flexShrink: 0,
    },
});

const modalContentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
    },
    header: [
        theme.fonts.xLargePlus,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const iconButtonStyles: Partial<IButtonStyles> = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};


const Archives = (): JSX.Element => {
    const [state, setState] = React.useState({
        allItems: [{
            source: "Fayetteville201903",
            name: "Fayetteville rebuilding trip",
            location: "Fayetteville, NC",
            date: "March 2019"
        } as ITripDetails],
        isLoading: true
    })
    const [items, setItems] = React.useState(state.allItems);
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
    const [currentItem, setCurrentItem] = React.useState<ITripDetails>()

    const getVideoSrc = () => {
        return `https://lbofwnc.blob.core.windows.net/trip-archives/${currentItem?.source}`;
    }

    useEffect(() => {
        const loadAllItems = async () => {

            const response = await fetch('https://lbofwnc.blob.core.windows.net/trip-archives/index.json', {
                mode: "cors"
            });
            if (response.ok) {
                const body = await response.json();

                setState({
                    allItems: body,
                    isLoading: false
                });
                setItems(body);
            }
        }

        loadAllItems();
    }, []);

    const rowClick = (ev: React.MouseEvent<HTMLDivElement>) => {
        const sIndex = ev.currentTarget.getAttribute('data-item-index')
        if (!sIndex)
            return;

        const index = Number.parseInt(sIndex);
        const item = items[index];

        setCurrentItem(item);
        showModal();
    }


    const onRenderCell = (item?: ITripDetails, index?: number | undefined): JSX.Element => {
        if (!item)
            return (<div></div>);

        return (
            <div className={classNames.itemCell} data-is-focusable={true} onClick={rowClick} data-item-index={index}>
                <div className={classNames.itemContent}>
                    <div className={classNames.itemName}>{item.name}</div>
                    <div className={classNames.itemIndex}>{item.location}</div>
                    <div>{item.date}</div>
                </div>
                <Icon className={classNames.chevron} iconName={getRTL() ? 'ChevronLeft' : 'ChevronRight'} />
            </div>
        );
    };



    const resultCountText =
        items.length === state.allItems.length ? '' : ` (${items.length} of ${state.allItems.length} shown)`;

    const onFilterChanged = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
        if (!newValue) {
            console.log("newValue")
            setItems(state.allItems);
            return;
        }

        const search = newValue.toLowerCase();

        setItems(state.allItems.filter(item => item.name.toLowerCase().indexOf(search) >= 0 ||
            item.location.toLowerCase().indexOf(search) >= 0 ||
            item.date.toLowerCase().indexOf(search) >= 0));
    };
    return (
        <Stack tokens={containerStackToken}>
            <Text variant="xLarge" block>
                Trip Archives
            </Text>
            <Text>
                Click on the links below to see pictures taken during some of our past trips.
            </Text>
            <div className="ms-Grid">
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-lg12 ms-xxl6">
                        <Stack.Item >
                            <FocusZone direction={FocusZoneDirection.vertical}>
                                <TextField
                                    label={'Filter by name' + resultCountText}
                                    // eslint-disable-next-line react/jsx-no-bind
                                    onChange={onFilterChanged}
                                />
                                <div className="archive-list ms-depth-8" data-is-scrollable="true">
                                    <List items={items} onRenderCell={onRenderCell} />

                                </div>
                            </FocusZone>

                        </Stack.Item>
                    </div>
                </div>
            </div>
            <Modal
                isOpen={isModalOpen}
                containerClassName={modalContentStyles.container}
                onDismiss={hideModal}
                isBlocking={false}
            >
                <div className={modalContentStyles.header}>
                    <span >{currentItem?.name} ({currentItem?.date})</span>
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={cancelIcon}
                        ariaLabel="Close popup modal"
                        onClick={hideModal}
                    />
                </div>
                <div className={modalContentStyles.body}>
                    <video width="640" height="480" controls autoPlay={true} >
                        <source src={getVideoSrc()} />
                    </video>
                </div>
            </Modal>

        </Stack>

    )
}

export default Archives;