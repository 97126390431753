import { Stack, Text, IStackTokens } from '@fluentui/react';

const containerStackToken: IStackTokens = {
    childrenGap: 10,
    padding: 5
}

const Faq = (): JSX.Element => (
    <Stack tokens={containerStackToken}>
        <Text variant="xLarge" block>
            Frequently Asked Questions
        </Text>
        <Text>
            Below are some frequently asked questions:
        </Text>
        <Text variant="xLarge" block>
            Skills
        </Text>
        <text>
            <div className="question ms-fontColor-themePrimary">
                Q: I&#39;m not a professional builder.&nbsp; Can I still
                qualify to go on a trip?
            </div>
            <div className="answer">
                A: Absolutely.&nbsp; Volunteers of many skill levels are needed.&nbsp;
                Just having a positive attitude and the will to try can go a long way.&nbsp; If there
                is anything you are not comfortable doing, let us know and we
                will work it out.
            </div>
        </text>
        <text>
            <div className="question ms-fontColor-themePrimary">
                Q: I don&#39;t do any building at all.&nbsp; Is there
                anything I could do to help out on a trip?
            </div>
            <div className="answer">
                A: There&#39;s a good chance your assistance will be most helpful.&nbsp; Frequently, we
                need a few people to assist with the daily chores such as cooking,
                cleaning, preparation, and set-up.&nbsp; Please ask Al how we can use your
                talents.
            </div>
        </text>
        <text>
            <div className="question ms-fontColor-themePrimary">
                Q: Do I need to bring any tools?
            </div>
            <div className="answer">
                A: If you have some hand tools that you are accustomed to using,&nbsp; it&#39;s
                a good idea to bring them.&nbsp; However, in most cases, some specialty
                power tools are provided at the work site.&nbsp; If you bring personal
                tools with you, be sure they are clearly labels with your name, in case
                they are misplaced.
            </div>
        </text>
        <Text variant="xLarge" block>
            Health
        </Text>
        <text>
            <div className="question ms-fontColor-themePrimary">
                Q: Are there any special health issues that need to
                be addressed before going on a trip?
            </div>
            <div className="answer">
                A: It is highly recommended that you have an up-to-date tetanus shot.&nbsp;
                Accidents can happen on a building site, so taking this precautionary
                measure can provide some peace of mind.
            </div>
        </text>
        <text>
            <div className="question ms-fontColor-themePrimary">
                Q: Is the work strenuous or hot?
            </div>
            <div className="answer">
                A: Sometimes the work can be rather strenuous.&nbsp; When you are at a building
                site, it is important that you keep yourself well hydrated and work at a comfortable
                pace in the heat.&nbsp; Work sites are generally <u>not</u> air
                conditioned.
            </div>
        </text>
        <text>
            <div className="question ms-fontColor-themePrimary">
                Q: Do I need a physical?
            </div>
            <div className="answer">
                A: No.&nbsp; A physical is not required.&nbsp; However, as is the case
                with all physical activity, it&#39;s a good idea to consult your physician before going on a trip.
            </div>
        </text>
    </Stack>
)

export default Faq;